@font-face {
  font-family: "Switzer";
  src: url(./css/fonts/Switzer/Switzer-Regular.ttf);
}
.swagger-ui{
  height: 100vh !important;
  overflow: scroll !important;
  padding-bottom: 200px !important;
}
.information-container{
  margin-top: -45px !important;
}
.tabContainer{
  gap: 2px;
}
.tab-active{
  cursor: pointer;
  min-width: 100px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -1.5px;
 border-bottom: solid 2px #F8B02B;
 color: #16171D;
 font-weight: 600;
}
.tab{
  font-weight: normal;
  cursor: pointer;
  min-width: 100px;
  padding: 2px 30px;
  height: 30px;
  text-align: center;
  display: flex;
  color: #5c5c5c;
  justify-content: center;
  align-items: center;
  margin-bottom: -1px;
  border-bottom: solid 1px transparent;
}
.divider{
  height: 1px;
  background-color: #ddd;
}
body {
  font-family: 'Switzer', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sidemenu {
  background-color: black;
  height: 100vh;
}

.sub {
  color: white;
  font-size: 14px;
  margin-top: 60px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: relative;
}

.top-0 {
  top: 0px;
}

.top-10 {
  top: 10px;
}

.top-20 {
  top: 20px;
}

.right-0 {
  right: 0px;
}

.right-10 {
  right: 10px;
}

.right-20 {
  right: 20px;
}
.left-0 {
  left: 0px;
}

.left-10 {
  left: 10px;
}

.left-20 {
  left: 20px;
}
.bottom-0 {
  bottom: 0px;
}

.bottom-10 {
  bottom: 10px;
}

.bottom-20 {
  bottom: 20px;
}

.logo-wrapper {
  position: absolute;
  bottom: 0px;
}

.title-text {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
}

.poweredby {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

.return-to-login-text {
  color: black;
}

.timer-text1 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(22, 23, 29, 1);
}

.timer-text2 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.Dashboard-items {
  list-style: none;
  z-index: 99999999;
  position: relative;
}

.Dashboard-items li a {
  margin-top: 20px;
  justify-items: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  color: rgba(138, 139, 142, 1);
  text-decoration: none;
}

.Dashboard-items li span {
  margin-left: 10px;
}

.Dashboard-items .active {
  color: white;
}

.Dashboard-items .inactive {
  color: rgba(138, 139, 142, 1);
}

.main-scrollable {
  height: 75vh;
  overflow: scroll;
}

.fCap {
  text-transform: capitalize;
}

.modal {
  background-color: rgba(0, 0, 0, 0.4) !important;
  display: block !important;
}

.filePickerContainer {
  overflow: hidden;
  padding: relative;
}

.filePicker {
  width: 100px;
  height: 80px;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0 !important;
  cursor: pointer;
}

.btn-inactive {
  width: 103px;
  height: 44px;
  padding: 12px, 24px, 12px, 24px;
  border-radius: 8px;
  border: 1px;
  background-color: #93939373;
}

.btn-yellow {
  width: 132px;
  height: 44px;
  padding: 12px, 24px, 12px, 24px;
  border-radius: 8px;
  background-color: #F8B02B;
}

.error {
  color: red;
  font-size: 12px;
}

.emailContainer {
  gap: 4px;
  display: flex;
}

.emailItem {
  display: flex;
  height: 28px;
  padding: 2.8px 10px;
  border-radius: 4px;
  border: 0.6px;
  border: 0.6px solid #A0A3A6;
  background-color: white;
  text-align: center;
  vertical-align: middle;
  margin: 5px 2px;
  font-size: 12px;
}

.noborder {
  border: none;
  outline: none;
}

.input-wrapper-b {
  border: solid 1px #ddd;
  background-color: red;
}

.btn-close-b {
  font-size: 8px;
  margin-left: 5px;
  margin-top: 3.5px;
}

.form-control input {
  width: 100%;
}

.pl-c .nav-link {
  color: #282c34;
  padding: 0px 10px;
}

.pl-c .nav-link-active {
  color: orange;
}

.dropdown-wrp {
  position: relative;
}

.dropdown-cnt {
  position: absolute;
  bottom: -100px;
  right: 0px;
  background-color: white;
  z-index: 9999;
}

.dropdown-cnt ul {
  list-style: none;
  padding: 5px 10px;
  padding-bottom: 0px;
  background-color: white;
}

.dropdown-cnt ul li {
  display: flex;
  align-items: center;
  min-width: 140px;
  font-size: 14px;
  padding: 10px 0px;
  cursor: pointer;
}

.dropdown-cnt ul li span {
  padding-left: 5px;
}

.btn-close {
  font-size: 14px;
}

.dx {
  color: #6F7174;
  font-size: small;
}

.dxx {
  font-size: medium;
}

.table-title {
  font-family: "Switzer", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.chart-title {
  font-family: "Switzer", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.012em;
}

.chart-date {
  font-family: "Switzer", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
}

.chart-tabs-container {
  width: 288px;
  height: 40px;
  border-radius: 8px;
  background: #F7F7F7;
  box-shadow: 0px 1px 10px 0px #00000014 inset;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 3px;
}

.chart-tabs-active {
  background-color: white;
}

.chart-tabs-container .col-4 {
  font-family: "Switzer", sans-serif;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.c-box-checkin {
  width: 16px;
  height: 16px;
  background-color: #3B6FE9;
}

.cb-txt {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
}

.c-box-checkout {
  width: 16px;
  height: 16px;
  background-color: #F8B02B;
}

.selected-tab {
  border-bottom: solid 2px #F8B02B;
  border-radius: 0px;
}

.orange-text {
  color: #F8B02B;
}

.calendar-card-left {
  z-index: 9999999 !important;
  display: flex;
  justify-content: center;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  right: -10px;
  top: 45px;
  z-index: 9999;
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.41);
}
.calendar-card-right {
  z-index: 9999999 !important;
  display: flex;
  justify-content: center;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  top: 45px;
  z-index: 9999;
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.41);
}
.react-calendar {
  border: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.filterButton {
  max-width: none !important;
}

.mx-1 {
  display: inline-block !important;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
}

.underlined {
  text-decoration: underline !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-11 {
  font-size: 14px !important;
}

.light-gray-text {
  color: #6F7174;
}
.nameTag{
  background-color: #FDF2D0;
  color: #F8B02B;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 20px;
}
input[type="checkbox"]{
  width: 20px;
  height: 20px;
  cursor: pointer;
  appearance: none;
  background: white;
  border: solid 1px #ddd;
  border-radius: 1px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

input[type=checkbox]:checked::before {
  content: "\2713";
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 22px;
  background-color: #F8B02B;
  border: solid 1px #F8B02B;
  width: 25px;
  height: 25px;
}
.active-status{
  border: solid 1px #3CC13B;
  background-color: #E0F5E0;
  border-radius: 20px;
  padding: 2px 10px;
  color: #3CC13B;
  font-size: 12px;
}
.suspended-status{
  border: solid 1px #B00020;
  background-color: #FEECEC;
  border-radius: 20px;
  padding: 2px 10px;
  color: #B00020;
  font-size: 12px;
}
.processing-status{
  border: solid 1px #FBDF88;
  background-color: #FDF2D0;
  border-radius: 20px;
  padding: 2px 10px;
  color: #FBDF88;
  font-size: 12px;
}
.active-badge{
  background-color: #F8B02B;
  border-radius: 4px;
  padding: 1px 5px;
  color: black;
  font-size: 12px;
}
.inactive-badge{
  background-color: #6F7174;
  border-radius: 4px;
  padding:1px 5px;
  color: #FFFFFF;
  font-size: 12px;
}
.qrcode-wrp{
  padding:20px;
  background-color: #16171D;
  border-radius: 2px;
  color: #F8B02B;
  text-align: center;
  font-size: 66.51px;
}
.qrcode-inner{
  height: 250px;
  width: 250px;
  background-color: white;
}
